import * as React from "react"
import MainLayout from "src/components/layoutmain";
import LayoutCentered from "src/components/layoutcentered";

/**
 * Page Contact
 * @returns {JSX.Element}
 * @constructor
 */
export default function Contact() {
    const pageProps = {
        title: "Le live bar - Contact",
        menuPageTitle: "Contact",
        description:
            "Vous produire ? Une collaboration ? Intérrésé pour faire un concert ou diffuser tout autre activité culturelle au live bar ?",
    };

    return (
        <MainLayout pageProps={pageProps}>
            <LayoutCentered>
                <div className={"contact"}>
                    <div className={"contact-header"}>
                        <h1>
                            Vous voulez vous produire&nbsp;? Une collaboration&nbsp;?
                        </h1>
                        <h4>
                            Intéressé pour faire un concert ou diffuser tout autre activité culturelle au live bar&nbsp;?
                        </h4>
                    </div>
                    <div className={"contact-content"}>
                        <p>N’hésitez pas à nous contacter avec les liens ci-dessous :</p>
                        <div className={"contact-content-items"}>
                            <div className={"contact-content-items-item"}>
                                <h3>Professionnel&nbsp;?</h3>
                                <a className={"link-contact"} href="mailto:lelivebar44@gmail.com">
                                    <span className={"icon-lelivebar-icon-mail"} />
                                    Par mail
                                </a>
                            </div>
                            <div className={"contact-content-items-item"}>
                                <h3>Particulier&nbsp;?</h3>
                                <a className={"link-contact"} href="//m.me/Lelivebar">
                                    <span className={"icon-lelivebar-logo-messenger"} />
                                    Par messenger
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutCentered>
        </MainLayout>
    )
}